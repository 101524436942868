<template>
    <div class="flex-container non-scrollable">
      <page-title value="Prospects"/>
      <tableau-porte :fields="fields" is-simulation/>
    </div>
</template>

<script>
import TableauPorte from '../../../components/TableauPorte'
import {mapState} from "vuex";
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: 'Prospects',
  components: {PageTitle, TableauPorte },
  data () {
    return {
      fields: [
          'indepLastName',
          'indepFirstName',
          'indepEmail',
          'date_created',
          'commercialFullName',
          'indepPhone',
          'averageDailyRate',
      ]
    }
  },
  computed: {
    ...mapState({
      simulations: state => state.simulation.simulations
    })
  },
  created () {
    this.$store.dispatch('simulation/getAllSimulationsForProspects').then(() => {
      this.simulations.forEach((simulation) => {
        simulation['jsonMission'] = simulation.jsonMissions.missions[0]?? {}
      })

      for (const simulation of this.simulations) {
        if (this.simulations.find(s => s.id !== simulation.id && s.independent.id === simulation.independent.id)) {
          this.simulations.splice(this.simulations.indexOf(simulation), 1)
        }
      }

    })
  }
}
</script>

<style scoped>

</style>
